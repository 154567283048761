<template>
    <div style="display: flex;padding: 20px;box-sizing: border-box;">
        <div style="flex: 1;">
            <div class="content-top">
                <p class="font-bold text-lg"> Student List</p>
            </div>
            <div style="padding: 20px 0px;padding-right: 20px;">
                <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
                    :highlight-current-row="false" :highlight-selection-row="false"
                    :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
                    :row-class-name="'custom-row-bg'">
                    <el-table-column prop="application_project" label="Student">
                        <template slot-scope="scope">
                            {{ scope.row.student.student_givenName_pinyin + ' ' +
                                scope.row.student.student_lastName_pinyin }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="student.student_email" label="Email"></el-table-column>
                    <el-table-column prop="student.student_graduation" label="Grad Year"
                        width="120px"></el-table-column>
                    <el-table-column prop="application_status" label="Status" width="120px">
                        <template slot-scope="scope">
                            {{ scope.row.application_status === '无需缴费' ? 'Paid' : 'Pending Payment' }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div style="width: 300px;color: #333;">
            <p class="font-bold text-lg"> Project Information</p>
            <p style="margin-top: 15px;">Project Name: {{ projectInfo.project_ename ? projectInfo.project_ename : projectInfo.project_name }}</p>
            <!-- <p style="margin-top: 15px;color: #666;font-size: 14px;line-height: 22px;" v-html="'视频显示，身穿救援队队服的救援人员冒雨在山里进行搜救。一名救援人员称，近日山里下雨，山上天气和能见度情况都不好。救援队、户外驴友团都在自发进行救援，已经搜救了几百条路线，陆续近千人参与其中。'"></p> -->
            <p style="margin-top: 15px;color: #666;font-size: 14px;line-height: 22px;" v-html="projectInfo.project_introduction"></p>
        </div>
    </div>
</template>
<script>
import {
    getProjectInfoById,
    getStudentAppsByAccount
} from '../../api/eae'

export default ({
    name: 'index',
    data() {
        return {
            projectInfo:{},
            account_id: this.$route.query.account_id,
            project_id: this.$route.query.project_id,
            dataList: [],
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            getStudentAppsByAccount(this.account_id, this.project_id).then((res) => {
                this.dataList = res.data
                loading.close()
            })
            getProjectInfoById(this.project_id).then((res)=>{
                this.projectInfo = res.data
            })
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        },
    }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
    font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}

.content-top {
    position: relative;
    margin: 0 20px;
}

.create_btn {
    position: absolute;
    top: 0;
    right: 0;
}
</style>